import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import { ga4CheckoutStarted } from '../services/GoogleAnalytics';
import Checkout from './Checkout';
import CheckoutComplete from './CheckoutComplete';
import Onboarding from './Onboarding';

const features = [
    'Access to all data',
    'Search and compare data as much as you want',
    'Take data-driven decisions',
    'Free 7 days trial',
    '30-day money-back guarantee',
];

export default function TieredPricing({ newsletterSuccess }) {
    const [onboardingOpen, setOnboardingOpen] = useState(false);
    const [checkoutOpen, setCheckoutOpen] = useState(false)
    const [checkoutCompleteOpen, setCheckoutCompleteOpen] = useState(false)

    const onSubmit = () => {
        setCheckoutOpen(false);
        setCheckoutCompleteOpen(true);
    };

    const openCheckoutMonthly = () => {
        setSelectedTier(tiers[0]);
        setOnboardingOpen(true);
        ga4CheckoutStarted(tiers[0].name);
    };

    const openCheckoutHalfYearly = () => {
        setSelectedTier(tiers[1]);
        setOnboardingOpen(true);
        ga4CheckoutStarted(tiers[1].name);
    };

    const openCheckoutYearly = () => {
        setSelectedTier(tiers[2]);
        setOnboardingOpen(true);
        ga4CheckoutStarted(tiers[2].name);
    };

    const tiers = [
        {
            name: 'Monthly',
            id: 'tier-monthly',
            href: '#',
            price: '$7.99',
            totalPrice: '$7.99',
            subPrice: '$7.99 billed monthly',
            description: 'No runtime, no commitment',
            checkoutSubText: 'monthly',
            checkout: () => openCheckoutMonthly(),
        },
        {
            name: 'Half-Yearly',
            id: 'tier-half-yearly',
            href: '#',
            price: '$6.49',
            totalPrice: '$38.94',
            subPrice: '$38.94 billed every 6 months',
            description: 'Subscribe for half a year and save ~18%',
            checkoutSubText: 'every 6 months',
            checkout: () => openCheckoutHalfYearly(),
        },
        {
            name: 'Yearly',
            id: 'tier-yearly',
            href: '#',
            price: '$4.99',
            totalPrice: '$59.88',
            subPrice: '$59.88 billed annually',
            description: 'Subscribe for a year and save ~37%',
            checkoutSubText: 'every 12 months',
            checkout: () => openCheckoutYearly(),
        },
    ];

    const [selectedTier, setSelectedTier] = useState(tiers[0]);

    return (
        <>
            <Onboarding open={onboardingOpen} setOpen={setOnboardingOpen} onSubmit={onSubmit} selectedTier={selectedTier} setCheckoutOpen={setCheckoutOpen} />
            <Checkout open={checkoutOpen} setOpen={setCheckoutOpen} onSubmit={onSubmit} selectedTier={selectedTier} />
            <CheckoutComplete open={checkoutCompleteOpen} setOpen={setCheckoutCompleteOpen} newsletterSuccess={newsletterSuccess} />
            <div id="pricing" className="bg-white py-16">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-4xl sm:text-center">
                        <h2 className="text-base font-semibold leading-7 text-indigo-600">Pricing</h2>
                        <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                            Subscribe and start your free trial
                        </p>
                    </div>
                    <p className="mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-600 sm:text-center">
                        Start your <b>7 day free trial now</b>. After 7 days, the selected subcription will start. You won't be charged if you cancel within the 7 day duration. Additionally, once the subscription starts, our <b>30 days money-back guarantee</b> applies.
                    </p>
                    <div className="mt-20 flow-root">
                        <div className="isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-8 divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-x lg:divide-y-0 xl:-mx-4">
                            {tiers.map((tier) => (
                                <div key={tier.id} className="pt-8 lg:px-8 lg:pt-0 xl:px-14">
                                    <h3 id={tier.id} className="text-base font-semibold leading-7 text-gray-900">
                                        {tier.name}
                                    </h3>
                                    <p className="mt-6 flex items-baseline gap-x-1">
                                        <span className="text-5xl font-bold tracking-tight text-gray-900">{tier.price}</span>
                                        <span className="text-sm font-semibold leading-6 text-gray-600">/month</span>
                                    </p>
                                    <p className="mt-3 text-sm leading-6 text-gray-500">{tier.subPrice}</p>
                                    <button onClick={() => tier.checkout()}
                                        href={tier.href}
                                        aria-describedby={tier.id}
                                        className="mt-10 w-full block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Start 7 Day Free Trial
                                    </button>
                                    <p className="mt-10 text-sm font-semibold leading-6 text-gray-900">{tier.description}</p>
                                    <ul className="mt-6 space-y-3 text-sm leading-6 text-gray-600">
                                        {features.map((feature) => (
                                            <li key={feature} className="flex gap-x-3">
                                                <CheckCircleIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                                {feature}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}