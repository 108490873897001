import React, { useEffect, useState } from 'react';
import '../App.css';
import CookieBanner from '../components/CookieBanner';
import Faqs from '../components/Faqs';
import Features from '../components/Features';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Newsletter from '../components/Newsletter';
import NewsletterSignupDone from '../components/NewsletterSignupDone';
import NewsletterSignupFailed from '../components/NewsletterSignupFailed';
import TieredPricing from '../components/TieredPricing';

function Home() {
    const [cookieBannerVisible, setCookieBannerVisible] = useState(false);
    const [newsletterSignupDoneOpen, setNewsletterSignupDoneOpen] = useState(false);

    const [newsletterSignupFailedOpen, setNewsletterSignupFailedOpen] = useState(false);
    const [newsletterSignupFailedMessage, setNewsletterSignupFailedMessage] = useState("");

    const newsletterSuccess = (boolean, message) => {
        setNewsletterSignupDoneOpen(boolean);
        setNewsletterSignupFailedOpen(!boolean);
        setNewsletterSignupFailedMessage(message);
    }

    const userReadCookieBanner = () => {
        localStorage.setItem('userReadCookieBanner', 'true');
        setCookieBannerVisible(false);
    };

    useEffect(() => {
        if (!localStorage.getItem('userReadCookieBanner')) {
            setCookieBannerVisible(true);
        }
    }, []);

    return (
        <div className="relative isolate overflow-hidden bg-white">
            <svg
                className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                aria-hidden="true"
            >
                <defs>
                    <pattern
                        id="0787a7c5-978c-4f66-83c7-11c213f99cb7"
                        width={200}
                        height={200}
                        x="50%"
                        y={-1}
                        patternUnits="userSpaceOnUse"
                    >
                        <path d="M.5 200V.5H200" fill="none" />
                    </pattern>
                </defs>
                <rect width="100%" height="100%" strokeWidth={0} fill="url(#0787a7c5-978c-4f66-83c7-11c213f99cb7)" />
            </svg>

            <Header />
            <Hero />
            <Features />
            {/* <Pricing newsletterSuccess={newsletterSuccess} /> */}
            <TieredPricing newsletterSuccess={newsletterSuccess} />
            <Faqs />
            <Newsletter newsletterSuccess={newsletterSuccess} />
            <NewsletterSignupDone open={newsletterSignupDoneOpen} setOpen={setNewsletterSignupDoneOpen} />
            <NewsletterSignupFailed open={newsletterSignupFailedOpen} setOpen={setNewsletterSignupFailedOpen} errorMessage={newsletterSignupFailedMessage} />
            <Footer />

            {cookieBannerVisible && <CookieBanner readHandler={userReadCookieBanner} />}
        </div>
    );
}

export default Home;
