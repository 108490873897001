import { ChevronRightIcon } from '@heroicons/react/20/solid';

function Hero() {
  return (
    <div id="home" className="mx-auto max-w-7xl px-6 sm:pb-14 pt-10 lg:flex lg:px-8 lg:pt-40">
      <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">
        <div className="mt-24 sm:mt-32 lg:mt-16">
          <div className="inline-flex space-x-6">
            <span className="rounded-full bg-indigo-600/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-600 ring-1 ring-inset ring-indigo-600/10">
              What's New
            </span>
            <a href="#pricing" className="rounded-full bg-indigo-600 inline-flex items-center px-3 space-x-2 text-sm font-medium leading-6 text-white hover:bg-indigo-500">
              <span>Start Your <b>Free</b> Trial</span>
              <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </a>
          </div>
        </div>
        <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          Stay ahead in the stock market
        </h1>
        <p className="mt-6 text-lg leading-8 text-gray-600">
          Real-time stock prices and financial statements should drive your investment decisions.
          Search, filter and compare stocks based on the data you care about.
          Skinny Eagle contains all data you need: stock prices, business data like income statements, balance sheets and cash flow statements as well as all relevant KPIs and ratios like the price to earnings ratio, dividend yield, return on equity and many more.
        </p>
        <p className="mt-6 text-lg leading-8 text-gray-600">
          All stock market data available at your fingertips - easy to use from your mobile screen!
        </p>
        <div className="mt-10 flex items-center gap-x-6">
          <a href="#features" className="text-sm font-semibold leading-6 text-gray-900">
            Features <span aria-hidden="true">→</span>
          </a>
          <a href="#pricing" className="rounded-full bg-indigo-600 text-sm font-semibold leading-6 text-white px-3 hover:bg-indigo-500">
            Free Trial <span aria-hidden="true">→</span>
          </a>
        </div>
      </div>


      <div className="mx-auto mt-10 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
        <div className="sm:h-[820px] sm:w-[400px]">
          <picture>
            <source srcSet="/img/app-screen-6.webp" type="image/webp" />
            <img
              src="/img/app-screen-6.png"
              alt="Go to the stock detail page to see more KPIs and financial data"
            />
          </picture>
        </div>
      </div>
    </div>
  );
}

export default Hero;