import { Dialog, Transition } from '@headlessui/react';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import valid from 'card-validator';
import creditCardType, { getTypeInfo } from 'credit-card-type';
import countries from 'i18n-iso-countries';
import english from 'i18n-iso-countries/langs/en.json';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ga4CheckoutCompleted } from '../services/GoogleAnalytics';

function Checkout({ open, setOpen, onSubmit, selectedTier }) {
    const { formState: { errors }, handleSubmit, register, clearErrors, reset } = useForm();
    const [expiryDate, setExpiryDate] = useState("");
    const [cardNumber, setCardNumber] = useState("");
    const [cvcDigits, setCvcDigits] = useState(3);

    countries.registerLocale(english);
    const countryList = Object.entries(countries.getNames('en'))
        .map(([code, name]) => ({ value: code, label: name }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const onCloseHandler = (bool) => {
        setOpen(bool);
        clearErrors();
        setExpiryDate("");
        setCardNumber("");
        reset();
    }

    const payWithCreditCardClicked = (data) => {
        ga4CheckoutCompleted(selectedTier.name);
        reset();
        setExpiryDate("");
        setCardNumber("");
        onSubmit();
    }

    const formatExpiryDate = (value) => {
        // Remove all non-digit characters
        const v = value.replace(/\D/g, '');

        // Add a slash after the second digit
        if (v.length >= 2) {
            return v.slice(0, 2) + ' / ' + v.slice(2);
        }

        return v;
    }

    const handleExpiryDateChange = (event) => {
        let value = event.target.value;
        if (event.nativeEvent.inputType === 'deleteContentBackward' && value.slice(-1) === '/') {
            value = value.slice(0, -3);
        } else {
            value = formatExpiryDate(value);
        }
        setExpiryDate(value);
    };

    const prettyCardNumber = (cardNumber, cardType) => {
        var card = getTypeInfo(cardType);

        if (card) {
            var offsets = [].concat(0, card.gaps, cardNumber.length);
            var components = [];

            for (var i = 0; offsets[i] < cardNumber.length; i++) {
                var start = offsets[i];
                var end = Math.min(offsets[i + 1], cardNumber.length);
                components.push(cardNumber.substring(start, end));
            }

            return components.join(" ");
        }

        return cardNumber;
    }

    const handleCardNumberChange = (value) => {
        let cleanValue = value.replace(/\s/g, '');
        let creditCard = creditCardType(cleanValue);
        if (creditCard.length === 1) {
            let pcn = prettyCardNumber(cleanValue, creditCard[0].type);
            setCardNumber(pcn);
            setCvcDigits(creditCard[0].code.size);
        } else {
            setCardNumber(cleanValue);
        }
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onCloseHandler}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6">
                                <div>
                                    <Dialog.Title as="h3" className="text-base text-center items-center justify-center font-semibold leading-6 text-gray-900">
                                        7 Day Free Trial - Subscribe {selectedTier.name} After
                                    </Dialog.Title>

                                    <div className="relative mt-3">
                                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                            <div className="w-full border-t border-gray-200" />
                                        </div>
                                    </div>

                                    <div className="mx-auto max-w-lg mt-6">
                                        <p className="text-sm">
                                            Try Skinny Eagle as much as you want for 7 days. After the 7 day trial period, your subscription will start and you will be charged <b>{selectedTier.totalPrice}</b> {selectedTier.checkoutSubText}.
                                            If you cancel within the 7 days free trial period, your card won't be charged. After 7 days, your <b>{selectedTier.name}</b> subscription will start.
                                        </p>

                                        <p className="text-sm mt-2">
                                            Additionally, once your subscription starts, you have a 30 day money-back guarantee. If you are not satisfied, you can drop us a message and we will refund your money - no questions asked.
                                        </p>

                                        <form className="mt-6" onSubmit={handleSubmit(payWithCreditCardClicked)}>
                                            <div className="grid grid-cols-12 gap-x-4 gap-y-3">

                                                <div className="col-span-full">
                                                    <fieldset>
                                                        <legend className="block text-sm font-medium leading-6 text-gray-900">Card Details</legend>
                                                        <div className="mt-2 -space-y-px rounded-md bg-white shadow-sm">
                                                            <div>
                                                                <label htmlFor="card-number" className="sr-only">
                                                                    Card number
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="card-number"
                                                                    id="card-number"
                                                                    className={`relative block w-full rounded-none rounded-t-md border-0 bg-transparent py-2 text-gray-900 ring-inset ${errors["card-number"] ? 'ring-2 ring-red-500 z-20' : 'ring-1 ring-gray-300 z-auto'} placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6`}
                                                                    placeholder="Card number"
                                                                    autoComplete="cc-number"
                                                                    value={cardNumber}
                                                                    {...register("card-number", {
                                                                        required: true,
                                                                        onChange: (e) => handleCardNumberChange(e.target.value),
                                                                        validate: (value) => valid.number(value).isValid,
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="flex -space-x-px">
                                                                <div className="w-1/2 min-w-0 flex-1">
                                                                    <label htmlFor="card-expiration-date" className="sr-only">
                                                                        Expiration date
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="card-expiration-date"
                                                                        id="card-expiration-date"
                                                                        className={`relative block w-full rounded-none rounded-bl-md border-0 bg-transparent py-2 text-gray-900 ring-inset ${errors["card-expiration-date"] ? 'ring-2 ring-red-500 z-20' : 'ring-1 ring-gray-300 z-auto'} placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6`}
                                                                        placeholder="MM / YY"
                                                                        value={expiryDate}
                                                                        {...register("card-expiration-date", {
                                                                            required: true,
                                                                            onChange: (e) => handleExpiryDateChange(e),
                                                                            validate: (value) => {
                                                                                return valid.expirationDate(formatExpiryDate(value)).isValid
                                                                            },
                                                                        })}
                                                                    />
                                                                </div>
                                                                <div className="min-w-0 flex-1">
                                                                    <label htmlFor="card-cvc" className="sr-only">
                                                                        CVC
                                                                    </label>
                                                                    <input
                                                                        type="password"
                                                                        name="card-cvc"
                                                                        id="card-cvc"
                                                                        className={`relative block w-full rounded-none rounded-br-md border-0 bg-transparent py-2 text-gray-900 ring-inset ${errors["card-cvc"] ? 'ring-2 ring-red-500 z-20' : 'ring-1 ring-gray-300 z-auto'} placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6`}
                                                                        placeholder="CVC"
                                                                        {...register("card-cvc", {
                                                                            required: true,
                                                                            validate: (value) => valid.cvv(value, cvcDigits).isValid,
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    {(errors["card-number"] || errors["card-expiration-date"] || errors["card-cvc"]) && <span className="block text-sm font-medium text-red-700">Please enter valid credit card information.</span>}
                                                </div>

                                                {/* <div className="col-span-full">
                                                    <fieldset className="bg-white">
                                                        <legend className="block text-sm font-medium leading-6 text-gray-900">Billing address</legend>
                                                        <div className="mt-2 -space-y-px rounded-md shadow-sm">
                                                            <div>
                                                                <label htmlFor="street-address" className="sr-only">
                                                                    Street and number
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="street-address"
                                                                    id="street-address"
                                                                    autoComplete="street-address"
                                                                    className={`relative block w-full rounded-none rounded-t-md border-0 bg-transparent py-2 text-gray-900 ring-inset ${errors["street-address"] ? 'ring-2 ring-red-500 z-20' : 'ring-1 ring-gray-300 z-auto'} placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6`}
                                                                    placeholder="Examplestreet 16"
                                                                    {...register("street-address", { required: true })}
                                                                />
                                                            </div>
                                                            <div className="flex -space-x-px">
                                                                <div className="w-1/2 min-w-0 flex-1">
                                                                    <label htmlFor="postal-code" className="sr-only">
                                                                        ZIP / Postal code
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="postal-code"
                                                                        id="postal-code"
                                                                        autoComplete="postal-code"
                                                                        className={`relative block w-full rounded-none border-0 bg-transparent py-2 text-gray-900 ring-inset ${errors["postal-code"] ? 'ring-2 ring-red-500 z-20' : 'ring-1 ring-gray-300 z-auto'} placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6`}
                                                                        placeholder="ZIP / Postal code"
                                                                        {...register("postal-code", { required: true })}
                                                                    />
                                                                </div>
                                                                <div className="min-w-0 flex-1">
                                                                    <label htmlFor="city" className="sr-only">
                                                                        City
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="city"
                                                                        id="city"
                                                                        className={`relative block w-full rounded-none border-0 bg-transparent py-2 text-gray-900 ring-inset ${errors["city"] ? 'ring-2 ring-red-500 z-20' : 'ring-1 ring-gray-300 z-auto'} placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6`}
                                                                        placeholder="City"
                                                                        {...register("city", { required: true })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <label htmlFor="country" className="sr-only">
                                                                    Country
                                                                </label>
                                                                <select
                                                                    id="country"
                                                                    name="country"
                                                                    autoComplete="country-name"
                                                                    className={`relative block w-full rounded-none rounded-b-md border-0 bg-transparent py-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6`}
                                                                >
                                                                    {countryList.map((country) => (
                                                                        <option key={country.value}>{country.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    {(errors["street-address"] || errors["city"] || errors["postal-code"]) && <span className="block text-sm font-medium text-red-700">Please enter a valid address.</span>}
                                                </div> */}
                                            </div>

                                            <button
                                                type="submit"
                                                className="mt-6 w-full rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                Start Free Trial
                                            </button>

                                            <p className="mt-6 flex justify-center text-sm font-medium text-gray-500">
                                                <LockClosedIcon className="mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                We don't save any payment details.
                                            </p>
                                        </form>
                                    </div>

                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default Checkout;