import jsonp from 'jsonp';
import { ga4NewsletterSubscriptionFailed, ga4NewsletterSubscriptionSuccess } from './GoogleAnalytics';

const NewsletterService = {
    subscribe: async (email, onSuccess, onError, source) => {
        const url = 'https://skinny-eagle.us21.list-manage.com/subscribe/post-json?u=dbb06854aee94c03c8f13d9d6&amp;id=01a0ee58a6&amp;f_id=008bf2e6f0';

        jsonp(`${url}&EMAIL=${email}`, { param: 'c' }, (err, data) => {
            if (err) {
                ga4NewsletterSubscriptionFailed(source, err);
                onError();
            } else if (data.result !== 'success') {
                ga4NewsletterSubscriptionFailed(source, data.msg);
                onError(data.msg);
            } else {
                ga4NewsletterSubscriptionSuccess(source);
                onSuccess(data.msg);
            }
        });
    }
};

export default NewsletterService;