import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import NewsletterService from '../services/NewsletterService';

function CheckoutComplete({ open, setOpen, newsletterSuccess }) {
    const { formState: { errors }, handleSubmit, register, clearErrors, reset } = useForm();

    const onSuccess = () => {
        newsletterSuccess(true, "");
    }

    const onError = message => {
        newsletterSuccess(false, message);
    }

    const onSubmit = async data => {
        const email = data["email-address"];
        NewsletterService.subscribe(email, onSuccess, onError, "after-checkout");
        setOpen(false);
        clearErrors();
        reset();
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-md sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            We did not charge you
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                And we did not save any of your information. This product is currently still under development. If you give us your email-address and sign-up for the newsletter, we will reach out as soon as the product is live and invite you for your <b>free trial</b>.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <form className="w-full max-w-md lg:pt-8" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="grid grid-cols-12 gap-x-4 gap-y-6">
                                        <div className="col-span-full">
                                            <label htmlFor="email-address" className="sr-only">
                                                Email address
                                            </label>
                                            <input
                                                id="email-address"
                                                name="email"
                                                type="email"
                                                autoComplete="email"
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                placeholder="Enter your email"
                                                {...register("email-address", {
                                                    required: true,
                                                    pattern: {
                                                        value: /\S+@\S+\.\S+/,
                                                        message: "Entered value does not match email format",
                                                    },
                                                })}
                                            />
                                            {errors["email-address"] && <span className="block text-sm font-medium text-red-700">Please enter a valid email address.</span>}
                                        </div>

                                        <div className="col-span-full">
                                            <button
                                                type="submit"
                                                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            >
                                                Subscribe
                                            </button>
                                        </div>
                                    </div>
                                    <p className="mt-4 text-sm leading-6 text-gray-900">
                                        We care about your data. Read our{' '}
                                        <a href="/data-policy" target="_blank" className="font-semibold text-indigo-600 hover:text-indigo-500">
                                            privacy&nbsp;policy
                                        </a>
                                        .
                                    </p>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default CheckoutComplete;