function CookieBanner({ readHandler }) {
    return (
        <div className="fixed inset-x-0 bottom-0 flex flex-col justify-between gap-x-8 gap-y-4 bg-white p-6 ring-1 ring-gray-900/10 md:flex-row md:items-center lg:px-8">
            <p className="max-w-4xl text-sm leading-6 text-gray-900">
                This site uses cookies to give you the best possible experience. By continuing to use the site you agree that we can save cookies on your device. For more information and details, read our Cookie section in our {' '}
                <a href="/data-policy" className="font-semibold text-indigo-600">
                    data policy
                </a>
                .
            </p>
            <div className="flex flex-none items-center gap-x-5">
                <button onClick={() => readHandler()} type="button" className="text-sm font-semibold leading-6 text-gray-900">
                    Close
                </button>
            </div>
        </div>
    )
}

export default CookieBanner;