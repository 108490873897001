import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactGA from 'react-ga4';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import DataPolicy from './pages/DataPolicy';
import Home from './pages/Home';
import NotFound from './pages/NotFound';

function App() {
  const TRACKING_ID = "G-YEN56NK9ZT"
  ReactGA.initialize(TRACKING_ID);

  return (
    <GoogleOAuthProvider clientId='1041306617903-1f69r7ceeef6hd58jsio5dp1079atbk8.apps.googleusercontent.com'>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/data-policy" element={<DataPolicy />} />
          <Route path='*' element={<NotFound />} />
          <Route path='/stock/app' element={<Navigate to="/" replace />} />
          <Route path='/stocks/app' element={<Navigate to="/" replace />} />
          <Route path='/share/app' element={<Navigate to="/" replace />} />
          <Route path='/shares/app' element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;
