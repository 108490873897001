import { Dialog, Transition } from '@headlessui/react';
import { useGoogleLogin } from '@react-oauth/google';
import { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { ga4UserSignUpFail, ga4UserSignedUp } from '../services/GoogleAnalytics';

export default function Onboarding({ open, setOpen, selectedTier, setCheckoutOpen }) {
    const { formState: { errors }, handleSubmit, register, reset } = useForm();

    const onCloseHandler = () => {
        setOpen(false);
        reset();
    }

    const loginFail = (response) => {
        ga4UserSignUpFail('Google');
    }

    const loginSuccess = (response) => {
        ga4UserSignedUp('Google');
        continueToCheckout();
    }

    const login = useGoogleLogin({
        onSuccess: loginSuccess,
        onError: loginFail,
    });

    const formSubmit = (data) => {
        ga4UserSignedUp('Email');
        continueToCheckout();
    }

    const continueToCheckout = () => {
        setOpen(false);
        setCheckoutOpen(true);
        reset();
    }

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={onCloseHandler}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6">
                                    <div>
                                        <Dialog.Title as="h3" className="text-base text-center items-center justify-center font-semibold leading-6 text-gray-900">
                                            Sign up and start your free trial
                                        </Dialog.Title>

                                        <div className="relative mt-3">
                                            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                <div className="w-full border-t border-gray-200" />
                                            </div>
                                        </div>

                                        <div className="mx-auto max-w-lg mt-6">
                                            <p className="text-sm">
                                                Create an account with your name, email and password to get started.
                                            </p>

                                            <form
                                                className="space-y-6 mt-3"
                                                onSubmit={handleSubmit(formSubmit)}>

                                                <div className="col-span-full">
                                                    <fieldset>
                                                        {/* <legend className="block text-sm font-medium leading-6 text-gray-900">eMail and name</legend> */}
                                                        <div className="mt-2 -space-y-px rounded-md bg-white shadow-sm">
                                                            <div>
                                                                <label htmlFor="card-number" className="sr-only">
                                                                    Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="name"
                                                                    id="name"
                                                                    className={`relative block w-full rounded-none rounded-t-md border-0 bg-transparent py-2 text-gray-900 ring-inset ${errors["name"] ? 'ring-2 ring-red-500 z-20' : 'ring-1 ring-gray-300 z-auto'} placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6`}
                                                                    placeholder="John Doe"
                                                                    autoComplete="name"
                                                                    {...register("name", { required: true })}
                                                                />
                                                            </div>
                                                            <div>
                                                                <label htmlFor="card-number" className="sr-only">
                                                                    eMail
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="email"
                                                                    id="email"
                                                                    className={`relative block w-full rounded-none border-0 bg-transparent py-2 text-gray-900 ring-inset ${errors["email"] ? 'ring-2 ring-red-500 z-20' : 'ring-1 ring-gray-300 z-auto'} placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6`}
                                                                    placeholder="john.doe@skinny-eagle.com"
                                                                    autoComplete="email"
                                                                    {...register("email", { required: true })}
                                                                />
                                                            </div>
                                                            <div>
                                                                <label htmlFor="card-number" className="sr-only">
                                                                    Password
                                                                </label>
                                                                <input
                                                                    id="password"
                                                                    name="password"
                                                                    type="password"
                                                                    autoComplete="current-password"
                                                                    required
                                                                    placeholder="••••••••"
                                                                    className={`relative block w-full rounded-none rounded-b-md border-0 bg-transparent py-2 text-gray-900 ring-inset ${errors["password"] ? 'ring-2 ring-red-500 z-20' : 'ring-1 ring-gray-300 z-auto'} placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6`}
                                                                    {...register("password", { required: true })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    {(errors["email"] || errors["name"]) && <span className="block text-sm font-medium text-red-700">Please enter a valid email address, name and a password.</span>}
                                                </div>

                                                <div>
                                                    <button
                                                        type="submit"
                                                        className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    >
                                                        Next
                                                    </button>
                                                </div>
                                            </form>

                                            <div className="relative mt-5">
                                                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                    <div className="w-full border-t border-gray-200" />
                                                </div>
                                                <div className="relative flex justify-center text-sm font-medium leading-6">
                                                    <span className="bg-white px-6 text-gray-900">Or continue with</span>
                                                </div>
                                            </div>

                                            <div className="mt-5">
                                                <button
                                                    onClick={() => login()}
                                                    className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
                                                >
                                                    <svg className="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
                                                        <path
                                                            d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                                                            fill="#EA4335"
                                                        />
                                                        <path
                                                            d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                                                            fill="#4285F4"
                                                        />
                                                        <path
                                                            d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                                                            fill="#FBBC05"
                                                        />
                                                        <path
                                                            d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                                                            fill="#34A853"
                                                        />
                                                    </svg>
                                                    <span className="text-sm font-semibold leading-6">Google</span>
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
};