import ReactGA from 'react-ga4';

const devMode = () => {
    const devMode = localStorage.getItem('devMode')
    if (devMode && devMode === 'true') {
        return true;
    }
    return false;
}

export function ga4UserSignedUp(signupType) {
    if (devMode()) {
        console.log('user_signed_up');
    } else {
        ReactGA.event({
            category: 'user_signed_up',
            action: `user_signed_up ${signupType}`,
        });
    }
}

export function ga4UserSignUpFail(signupType) {
    if (devMode()) {
        console.log('user_sign_up_fail');
    } else {
        ReactGA.event({
            category: 'user_sign_up_fail',
            action: `user_sign_up_fail ${signupType}`,
        });
    }
}

export function ga4CheckoutStarted(subscriptionType) {
    if (devMode()) {
        console.log('checkout_started');
    } else {
        ReactGA.event({
            category: 'checkout_started',
            action: `checkout_started ${subscriptionType}`,
        });
    }
}

export function ga4CheckoutCompleted(subscriptionType) {
    if (devMode()) {
        console.log('checkout_completed');
    } else {
        ReactGA.event({
            category: 'checkout_completed',
            action: `checkout_completed ${subscriptionType}`,
        });
    }
}

// source would be either after checkout or in the footer
export function ga4NewsletterSubscriptionSuccess(source) {
    if (devMode()) {
        console.log('newsletterSubscriptionSuccess');
    } else {
        ReactGA.event({
            category: 'Newsletter',
            action: `Newsletter subscription from ${source}`,
        });
    }
}

// source would be either after checkout or in the footer
export function ga4NewsletterSubscriptionFailed(source, error) {
    if (devMode()) {
        console.log('newsletterSubscriptionFailed');
    } else {
        ReactGA.event({
            category: 'Newsletter',
            action: `Newsletter subscription failed from ${source}`,
            label: error,
        });
    }
}

export function ga4FaqClicked(question) {
    if (devMode()) {
        console.log('ga4FaqClicked');
    } else {
        ReactGA.event({
            category: 'FAQ',
            action: `Clicked on FAQ: ${question}`,
        });
    }
}

export function ga4FeaturesCarouselClicked(number) {
    if (devMode()) {
        console.log('ga4FeaturesCarouselClicked');
    } else {
        ReactGA.event({
            category: 'Features',
            action: `Clicked on feature carousel`,
            label: `${number}`
        });
    }
}

export function ga4HeaderNavigationClicked(name) {
    if (devMode()) {
        console.log('ga4HeaderNavigationClicked');
    } else {
        ReactGA.event({
            category: 'Header',
            action: `Clicked on navigation: ${name}`,
        });
    }
}