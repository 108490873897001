import { BoltIcon } from '@heroicons/react/20/solid';
import { Carousel } from 'flowbite-react';
import { ga4FeaturesCarouselClicked } from '../services/GoogleAnalytics';

const features = [
    {
        name: 'Filter by KPIs',
        description:
            'Choose from all available ratios, metrics and KPIs which are important to you. Define filters - for example dividend yield greater than 1% and price to earnings lower than 25.',
        icon: BoltIcon,
    },
    {
        name: 'Screen data',
        description: 'Screen, sort and filter the whole stock market based on your previous selection of KPIs. Choose, which data you want to focus on in the overview.',
        icon: BoltIcon,
    },
    {
        name: 'Stock Details',
        description: 'Open stocks in the detail page to see all KPIs and financial data. Additionally, real-time stock price and its history are available.',
        icon: BoltIcon,
    },
    {
        name: "Historic fundamentals",
        description: "See the history of all financial fundamental data like the Income Statement, Balance Sheet or Cash Flow Statement.",
        icon: BoltIcon,

    },
    {
        name: "Ratios",
        description: "View all available KPIs, ratios and metrics. Check their current values and their historic development. Compare them to the industry or market average.",
        icon: BoltIcon,

    }
];

function Features() {
    const carouselClicked = (number) => {
        if (number !== 0) {
            ga4FeaturesCarouselClicked(number);
        }
    }

    return (
        <div id="features" className="mx-auto max-w-7xl px-6 mt-10 sm:py-16 lg:px-8 lg:py-16">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-10 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                <div className="lg:ml-auto lg:pl-4 lg:pt-4">
                    <div className="lg:max-w-lg">
                        <h2 className="text-base font-semibold leading-7 texta-indigo-600">Compare KPIs and performance</h2>
                        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Data insights for the stock market</p>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            Screen the market based on your KPIs. See how KPIs compare historically within an industry, sector or market.
                        </p>
                        <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                            {features.map((feature) => (
                                <div key={feature.name} className="relative pl-9">
                                    <dt className="inline font-semibold text-gray-900">
                                        <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                                        {feature.name}
                                    </dt>{' '}
                                    <dd className="inline">{feature.description}</dd>
                                </div>
                            ))}
                            <div className="lg:hidden relative pl-9">
                                <dt className="inline font-semibold text-gray-900">
                                    <a href="#pricing">
                                        <BoltIcon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                                        <span className="rounded-full bg-indigo-600 px-3 py-1 text-white hover:bg-indigo-500">
                                            Start Your Free Trial <span aria-hidden="true">→</span>
                                        </span>
                                    </a>
                                </dt>
                            </div>
                        </dl>
                    </div>
                </div>
                <div className="flex items-start justify-center lg:order-first">
                    <div className="sm:h-[820px] sm:w-[400px] w-full h-screen-plus-50">
                        <Carousel slide={false} draggable={false} indicators={false} onSlideChange={(number) => carouselClicked(number)}>
                            <picture>
                                <source srcSet="/img/app-screen-4.webp" type="image/webp" />
                                <img
                                    src="/img/app-screen-4.png"
                                    alt="Go to the stock detail page to see more KPIs and financial data"
                                />
                            </picture>
                            <picture>
                                <source srcSet="/img/app-screen-5.webp" type="image/webp" />
                                <img
                                    src="/img/app-screen-5.png"
                                    alt="See the yearly and quarterly history of financial data like the Income Statement or Balance Sheet"
                                />
                            </picture>
                            <picture>
                                <source srcSet="/img/app-screen-1.webp" type="image/webp" />
                                <img
                                    src="/img/app-screen-1.png"
                                    alt="Select columns (KPIs) for the stock overview"
                                />
                            </picture>
                            <picture>
                                <source srcSet="/img/app-screen-2.webp" type="image/webp" />
                                <img
                                    src="/img/app-screen-2.png"
                                    alt="Filter the stock overview for KPIs, for example price to earnings or price to sales"
                                />
                            </picture>
                            <picture>
                                <source srcSet="/img/app-screen-3.webp" type="image/webp" />
                                <img
                                    src="/img/app-screen-3.png"
                                    alt="Order and browse the stock market based on your KPIs"
                                />
                            </picture>

                            <picture>
                                <source srcSet="/img/app-screen-6.webp" type="image/webp" />
                                <img
                                    src="/img/app-screen-6.png"
                                    alt="See the current and historic development of the KPIs and raios like price to earnings or price to sales and see how your selected stock compares to averages of the industry or market"
                                />
                            </picture>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Features;